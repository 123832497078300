import React from 'react';
import './Archive.css';

function Archive() {
  return (
    <div className="background-image">
        <div className="archive-container">
        <h1>雑誌のアーカイブ</h1>
        <ul>
            <li><a href="/path/to/January_issue.pdf" className="pdf-link" target="_blank" rel="noopener noreferrer">1月号</a></li>
            <li><a href="/path/to/February_issue.pdf" className="pdf-link" target="_blank" rel="noopener noreferrer">2月号</a></li>
            {/* 他の月のPDFへのリンクも同様に追加 */}
        </ul>
        <div>
            <a href="/" className="return-home">
                ホームに戻る
            </a>
        </div>
        </div>
    </div>
  );
}

export default Archive;
