import React, { useState } from 'react';
import './App.css';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closePopup = () => {
    const popupElement = document.querySelector('.popup');
    if (popupElement) {
      popupElement.classList.add('hidden'); // フェードアウト開始
      setTimeout(() => setShowPopup(false), 500); // 0.5秒後にポップアップを完全に非表示
    }
  };

  return (
    <div className="App">
      {showPopup && (
        <div className="popup" onClick={closePopup}>
          <img src="/background_org-logo.png" alt="Welcome" className="popup-image" />
        </div>
      )}
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={isOpen ? "menu-content open" : "menu-content"}>
        <a href="https://discord.gg/FSdMugpvfh" className="menu-link" target="_blank" rel="noopener noreferrer">Discord</a>
        <a href="https://twitter.com/cyberhippie_mag" className="menu-link" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://instagram.com/CyberHippie_mag" className="menu-link" target="_blank" rel="noopener noreferrer">Instagram</a>
        <p className="menu-link">Coming Soon...</p>
      </div>
      <div className="background-image">
        {/* 背景画像はここに表示される */}
      </div>
    </div>
  );
}

export default App;
